
const CC= null;//require('currency-converter-lt');
let crrncCnvtr=new CC({from:"USD", to:"NGN", amount:1, isDecimalComma:true});
crrncCnvtr.rates().then((response)=>{
    console.log(response);
    document.getElementById('rate').innerHTML=response + 150;
});
mnBtn=document.getElementById('mnBtn');
function rdrctLnk(){
    nmVal= document.getElementById('nm').value;
    trdVal= document.getElementById('trd').value;
    amntVal= document.getElementById('amnt').value;
    ntsVal= document.getElementById('nts').value;
    link="https://wa.me/+2348103628512?text=Hello, I am "+nmVal+", I want to trade"+amntVal+" "+trdVal+". I would also "+ntsVal;
    window.open(encodeURI(link),'_blank');
}
function hdClck(){
    link="https://wa.me/+2348103628512?text= Hello, my name is "+document.getElementById('mnInpt').value+". I want to trade. ";
    window.open(encodeURI(link),'_blank');
}
function btnDsbld(){
    nmVal= document.getElementById('nm').value;
    trdVal= document.getElementById('trd').value;
    amntVal= document.getElementById('amnt').value;
    ntsVal= document.getElementById('nts').value;
    document.getElementById("trdBtn").disabled=false;
    if(nmVal==""||trdVal==""||amntVal==""){document.getElementById("trdBtn").disabled=true;}
}
function ChngRt(){
    http = new XMLHttpRequest();var value;
    http.onreadystatechange = function (){
        if(http.readyState==4 && http.status == 200){
            value=JSON.parse(http.responseText);
            KObj=value['rate'];
            document.getElementById('rate').innerHTML=new Intl.NumberFormat('en-US').format(KObj);
            //ChngDt(KObj[cnt][0]);
        }
    };http.open("GET","db.win",true);http.send();
}
function ChngDt(dt){
    today=new Date();
    mnDt=new Date(dt);
    TLeft=mainday-today;
    document.getElementById('updt').innerHTML="Updated 2hrs Ago";
}